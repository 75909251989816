<template>
  <div class="careerlink">
    <img class="bg" src="@/assets/image/pc/careerdetail/头图.jpg">
    <div class="title">
      <p class="title_en">JOIN US</p>
      <p class="title_zh">有你而精彩</p>
    </div>
    <div class="link">
      <div class="leftsocial">
        <a
          target="_blank"
          href="https://app.mokahr.com/social-recruitment/gggames/46562#/"
        >
          <p>社会招聘</p>
        </a>
      </div>
      <div class="rightschool">
        <a
          target="_blank"
          href="https://app.mokahr.com/campus-recruitment/gggames/45224#/"
        >
          <p>校园招聘</p>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CareerLink',
  data() {
    return {
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.careerlink{
  height: 827px;
  .bg{
    position: absolute;
    width: 1920px;
    height: 827px;
  }
  .title{
    position: absolute;
    left: 799px;
    top: 460px;
    text-align: center;
    .title_en{
      font-size: 80px;
      font-family: Poppins;
      font-weight: bold;
      color: #FDFEFA;
      opacity: 0.76;
      line-height: 68px;
    }
    .title_zh{
      margin-top: 39px;
      font-size: 35px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FDFEFA;
      line-height: 40px;
    }
  }
  .link{
    position: relative;
    display: flex;
    top: 600px;
    left: 816px;
    width: 298px;
    height: 72px;
    p{
      margin-top: 20px;
      text-align: center;
      font-size: 21px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FDFEFA;
      cursor: pointer;
    }
    .leftsocial{
      cursor: pointer;
      position: relative;
      width: 148px;
      height: 72px;
      background: #E60012;
      border-radius: 36px 0 0 36px;
    }
    .rightschool{
      cursor: pointer;
      position: relative;
      width: 148px;
      height: 72px;
      background: #82131C;
      border-radius: 0 36px 36px 0;
    }
  }
}
</style>
