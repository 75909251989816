<template>
  <div class="careerposition">
    <div class="scrowcareer">
      <swiper
        style="height: 100%"
        :options="swiperOptions"
      >
        <!-- <ul> -->
        <swiper-slide v-for="career in careerList" :key="career.id">
          <li>
            <img :src="career.picture">
            <p class="careerName">{{ career.careerName }}</p>
            <p class="description">{{ career.description }}</p>
          </li>
        </swiper-slide>
        <!-- </ul> -->
      </swiper>
      <div class="swiper-button-prev swiper-button-black" /><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
      <div class="swiper-button-next swiper-button-black" /><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
      <div class="swiper-pagination" />
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'CareerPosition',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 2,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
          // bulletClass: 'my-bullet', // 需设置.my-bullet样式
          // bulletActiveClass: 'my-bullet-active'
        }
      },
      careerList: [
        {
          id: 1,
          careerName: '开发工程师',
          description: '我非常喜欢现在的团队，有什么不同的技术实现方式都可以第一时间讨论，没什么顾虑。',
          picture: require('@/assets/image/pc/careerdetail/index-zp_16.jpg')
        },
        {
          id: 2,
          careerName: '美术设计师',
          description: '完全不担心技术提升的问题，团队内有大佬可以随时请教，内部比赛也挺多的，自我练习的同时还能集百家之长，何乐而不为？',
          picture: require('@/assets/image/pc/careerdetail/index-zp_18.jpg')
        },
        {
          id: 3,
          careerName: '游戏策划',
          description: '可以发挥自己的奇思妙想，也可以得到大佬们的指点，解锁游戏设计新思路!',
          picture: require('@/assets/image/pc/careerdetail/gamedesign.jpg')
        },
        {
          id: 4,
          careerName: '游戏测试',
          description: '上班玩游戏，再也不会被长辈说不务正业啦，实现游戏自由的同时也找了适合自己的职业发展，特别是站着办公轻松解决久坐难题',
          picture: require('@/assets/image/pc/careerdetail/gametest.png')
        }
      ]
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.swiper-pagination {
  width: 200px;
  position: absolute;
  left: 690px;
  top: 800px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.swiper-button-prev{
  left: -60px;
}
.swiper-button-next{
  right: -30px;
}
.swiper-slide{
  display: flex;
  justify-content: space-between;
  height: 725px;
  li{
    background: #FFFFFF;
    width: 782px;
    height: 724px;
    position: relative;
    img{
      position: relative;
      width: 781px;
      height: 436px;
    }
    .careerName{
      position: relative;
      margin-top: 60px;
      margin-left: 49px;
      font-size: 29px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #1E1F1F;
      line-height: 52px;
    }
    .description{
      width: 680px;
      position: relative;
      margin-top: 30px;
      margin-left: 44px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1E1F1F;
      line-height: 37px;
    }
  }
}
.careerposition{
  width: 1920px;
  height: 900px;
  background: #E7E6E3;
  position: relative;
  .scrowcareer{
    position: relative;
    left: 146px;
    top: 38px;
    width: 1610px;
    ul{
      display: flex;
      justify-content: space-between;
      height: 725px;
      li{
        background: #FFFFFF;
        width: 782px;
        height: 724px;
        position: relative;
        img{
          position: relative;
          width: 781px;
          height: 436px;
        }
        .careerName{
          position: relative;
          margin-top: 60px;
          margin-left: 49px;
          font-size: 29px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1E1F1F;
          line-height: 52px;
        }
        .description{
          width: 680px;
          position: relative;
          margin-top: 30px;
          margin-left: 44px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1E1F1F;
          line-height: 37px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.swiper-pagination-bullet{
  background: #ffffff;
  opacity: 1;
  width: 1rem;
  height:1rem;
  background-size: 100% 100%;
}
.swiper-pagination-bullet-active {
  background: #000000;
  width: 1rem;
  height: 1rem;
  background-size: 100% 100%;
}
</style>
