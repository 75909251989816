<template>
  <div class="career">
    <Head />
    <CareerLink />
    <Introduce />
    <CareerPosition />
    <Questions />
    <Floor />
  </div>
</template>
<script>
import Head from '@/views/Head'
import Floor from '@/views/Floor'
import CareerLink from './CareerLink'
import Introduce from './Introduce'
import CareerPosition from './CareerPosition'
import Questions from './Questions'

export default ({
  name: "CareerDetail",
  components: {
    Head,
    Floor,
    CareerLink,
    Introduce,
    CareerPosition,
    Questions
  }
})
</script>

<style lang="scss" scoped>
.career{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>
