<template>
  <div class="questions">
    <p class="title">招聘所有问题</p>
    <ul>
      <li>
        <p class="questionTitle">我能申请多个职位吗？</p>
        <p class="questionContent">不建议申请多个职位哦。非常高兴您是一位全能多面手，不过我们仍希望您能仔细考虑自己的技能和兴趣所在，找到最适合自己的那一个。</p>
      </li>
      <li>
        <p class="questionTitle">我此前申请/面试过域起的职位，但未能通过。是否可以再次申请？</p>
        <p class="questionContent">当然可以。士别三日当刮目相看，只要您认为当前的能力和状态符合我们最新的岗位需求， 我们非常欢迎您的再次投递。</p>
      </li>
      <li>
        <p class="questionTitle">如何应聘？</p>
        <p class="questionContent">官网内选择相应岗位直接投递或发送简历至邮箱hr@gg.com（备注投递岗位）</p>
      </li>
      <div class="button"> 
        <p>联系我们：hr@gg.com</p>
      </div>
    </ul>
  </div>
</template>
<script>

export default ({
  name: "Questions"
})
</script>

<style lang="scss" scoped>
.questions{
  height: 887px;
  position: relative;
  .title{
    position: relative;
    margin-top: 112px;
    text-align: center;
    font-size: 54px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #292826;
    line-height: 83px;
  }
  ul{
    margin: 62px auto;
    width: 800px;
    position: relative;
    li{
      margin-bottom: 84px;
      .questionTitle{
        font-size: 25px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #292826;
        line-height: 33px;
      }
      .questionContent{
        margin-top: 28px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #292826;
        line-height: 28px;
      }
    }
    .button{
      width: 270px;
      height: 52px;
      background: rgba(230, 0, 18, 0);
      border: 1px solid rgba(41, 40, 38, 0.25);
      border-radius: 26px;
      margin: 0 auto;
      p{
        margin-top: 4px;
        text-align: center;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #292826;
        line-height: 44px;
      }
    }
  }
}
</style>
