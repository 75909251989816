<template>
  <div class="introduce">
    <div class="introduceTitle">
      <p class="Ititle">为什么你会喜欢这里</p>
      <p class="Idescrip">宽敞舒适的办公环境，平等有爱的文化氛围。 品质为先，用心打磨，创造用户喜爱的产品。 突破自我，与业界资深大咖共创游戏未来。</p>
    </div>
    <div class="Iicondes">
      <ul>
        <li>
          <img src="@/assets/image/pc/careerdetail/index-zp_03.png">
          <p>具有行业竞争力的薪资和每年2次调薪机会！专家路线or管理路线发展晋升双通道！</p>
        </li>
        <li>
          <img src="@/assets/image/pc/careerdetail/index-zp_05.png">
          <p>五险一金+补充公积金+补充医疗保险、餐补、团建基金、伯乐奖、带薪年假、节日福利、年度体检、各种零食饮料无限量供应......</p>
        </li>
        <li>
          <img src="@/assets/image/pc/careerdetail/index-zp_10.png">
          <p>作为高新企业我们的同事可以享受应届生/海外留学生落户加分及居转户年限减免，优秀者可直接申请落户直通名额！</p>
        </li>
        <li>
          <img src="@/assets/image/pc/careerdetail/index-zp_12.png">
          <p>如果你是喵星达人，上班即可遇见司宠“团团一家”，轻松实现撸猫自由！</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Introduce',
  data() {
    return {
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.introduce{
  margin-bottom: 100px;
  .introduceTitle{
    position: relative;
    top: 111px;
    .Ititle{
      position: relative;
      font-size: 54px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #292826;
      line-height: 83px;
      text-align: center;
    }
    .Idescrip{
      position: relative;
      margin-left: 764px;
      margin-top: 38px;
      width: 400px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #292826;
      line-height: 39px;
      text-align: center;
    }
  }
  .Iicondes{
    position: relative;
    margin-left: 547px;
    margin-top: 200px;
    width: 900px;
    height: 350px;
    ul{
      display: flex;
      flex-wrap: wrap;
      li{
        height: 175px;
        width: 450px;
        position: relative;
        display: flex;
        img{
          width: 79px;
          height: 79px;
          position: relative;
          display: inline;
        }
        p{
          margin-left: 25px;
          margin-top: 15px;
          margin-right: 50px;
          position: relative;
          display: inline;
          font-size: 19px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 38px;
          color: #292826;
        }
      }
    }
  }
}
</style>
